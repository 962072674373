import queryString from 'query-string'
import { isBrowser } from '../utils/auth'

export const shopEnabled = () => {
  if (!isBrowser) {
    return
  }

  const { shop } = queryString.parse(window.location.search)
  return !!shop
}

export const loginEnabled = () => {
  if (!isBrowser) {
    return
  }

  const { login } = queryString.parse(window.location.search)
  return !!login
}

import queryString from 'query-string'
import axios from 'axios'

export const isBrowser = typeof window !== 'undefined'

export const handleAuthentication = async (url, provider = 'google') => {
  if (!isBrowser) {
    return
  }

  const callBackParams = queryString.parse(window.location.search)

  const requestURL = `${url}/auth/${provider}/callback`
  const { data } = await axios.get(requestURL, { params: callBackParams })

  if (data.jwt) {
    setToken(data.jwt)
    setUserData(JSON.stringify(data.user))

    window.opener.setAuthenticated()
    window.close()
  }
}

export const logout = (callback) => {
  if (!isBrowser) return
  window.localStorage.removeItem(USER_DATA_KEY)
  window.localStorage.removeItem(TOKEN_KEY)
  if (callback) {
    callback()
  }
}

const USER_DATA_KEY = 'userData'
const TOKEN_KEY = 'token'

export const getUserData = () => {
  if (!isBrowser) return
  const value = window.localStorage.getItem(USER_DATA_KEY)
  return JSON.parse(value)
}

export const setUserData = (userData) => {
  if (!isBrowser) return
  return window.localStorage.setItem(USER_DATA_KEY, userData)
}

export const getToken = () => {
  if (!isBrowser) return
  return window.localStorage.getItem(TOKEN_KEY)
}

export const setToken = (token) => {
  if (!isBrowser) return
  return window.localStorage.setItem(TOKEN_KEY, token)
}

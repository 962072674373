import React from 'react'
import { Link } from 'gatsby'
import ErrorBoundary from './ErrorBoundary'
import EmailSubscribe from './EmailSubscribe'
import * as utils from '../utils'
import { FaTwitter, FaFacebook } from 'react-icons/fa'

const Footer = () => {
  const mailingList = 'ssc-general'
  const savedEmail = utils.getLocalStorage(`email:${mailingList}`)
  return (
    <footer className="footer has-background-black has-text-centered has-text-white">
      <div className="content has-text-centered">
        <div className="content has-text-centered has-text-white has-background-black has-text-white-ter">
          <Link to="/">الرئيسية</Link>&nbsp;|&nbsp;
          <Link to="/about">عن المركز</Link>&nbsp;|&nbsp;
          <Link to="/blog">مقالات ونصوص</Link>&nbsp;|&nbsp;
          <Link to="/art">زاوية التشكيل</Link>&nbsp;|&nbsp;
          {/* <Link to='/auth'>منطقة الأعضاء</Link>&nbsp;|&nbsp; */}
          <Link to="/magazine">المجلة</Link>&nbsp;|&nbsp;
          <Link to="/books">كتب المركز</Link>&nbsp;|&nbsp;
          <Link to="/contact">اتصل بنا</Link>
        </div>

        <div className="content has-text-centered has-text-danger is-small">
          اللوحات المستخدمة في الموقع هي للفنان التشكيلي السوداني الراحل حسان
          علي أحمد
        </div>
        <div className="content has-text-centered">
          <div className="columns is-gapless">
            <div className="column has-text-centered">
              <ErrorBoundary>
                <EmailSubscribe
                  label="اضفني الى قائمة المركز البريدية"
                  labelClassName="has-text-white"
                  list={mailingList}
                  successMessage={`تمت اضافة عنوانك ${
                    savedEmail || ''
                  } الى قائمة المركز البريدية.`}
                  successClass="content is-small has-text-success has-text-centered"
                  isCentered
                  persist={false}
                />
              </ErrorBoundary>
            </div>
          </div>
          <div className="content has-text-centered">
            <a
              title="twitter"
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/SudanSsc"
            >
              <FaTwitter />
            </a>{' '}
            &nbsp;
            <a
              title="facebook"
              target="_blank"
              rel="noreferrer"
              href="https://facebook.com/SudanSsc"
            >
              <FaFacebook />
            </a>
            <br />
          </div>
        </div>
        <br />
      </div>
    </footer>
  )
}

export default Footer

import moment from 'moment'

export const isBrowser = typeof window !== 'undefined'

export const formatDate = (date) => {
  try {
    return moment(date).format('DD-MM-yyyy')
  } catch (err) {
    return date
  }
}

export const getLocalStorage = (key) => {
  if (!isBrowser) return
  return window.localStorage.getItem(key)
}

export const setLocalStorage = (key, value) => {
  if (!isBrowser) return
  return window.localStorage.setItem(key, value)
}

export const getRandomBackground = () => {
  const images = [
    '/img/backgrounds/hassanbg_4.jpeg',
    '/img/backgrounds/hassanbg_3.jpeg',
    '/img/backgrounds/hassanbg_2.jpeg',
    '/img/backgrounds/hassanbg_5.jpeg',
    '/img/backgrounds/hassanbg_1.jpeg',
  ]

  const random = Math.floor(Math.random() * 10)
  return images[(random % images.length) + 1] || images[0]
}

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/all.sass'
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from 'gatsby'

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata()
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />

        <script
          src="https://cdn.onesignal.com/sdks/OneSignalSDK.js"
          defer
        ></script>
        <script>
          {`
              var OneSignal = window.OneSignal || [];
              OneSignal.push(function() {
                OneSignal.init({
                  appId: "${process.env.GATSBY_ONESIGNAL_ID}",
                  allowLocalhostAsSecureOrigin: true
                });
              });
            `}
        </script>

        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:locale" content="ar_TN" />
        <meta property="og:image" content={`${withPrefix('/')}img/logo.jpg`} />
        <meta
          name="twitter:image"
          content="https://ssc-sudan.org/img/logo.jpg"
        />
        <meta name="twitter:card" content="summary_large_image"></meta>
        <meta charset="utf-8" />
      </Helmet>
      <div id="fb-root" />
      <Navbar />
      {children}
      <Footer />
    </div>
  )
}

TemplateWrapper.propTypes = {
  children: PropTypes.func,
}

export default TemplateWrapper

import React from 'react'
import { Link, navigate } from 'gatsby'
import { getToken, logout } from '../utils/auth'
import { loginEnabled } from '../utils/experiments'
import { FaTwitter, FaFacebook } from 'react-icons/fa'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  render() {
    const authenticated = !!getToken()
    return (
      <div className="container">
        <nav className="navbar" role="navigation" aria-label="main-navigation">
          <div className="navbar-brand">
            <Link to="/" title="Logo">
              <img
                alt="شعار مركز الدراسات السودانية"
                className="site-logo"
                src="/img/ssc-logo.jpeg"
              />
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-start">
              <Link className="navbar-item" to="/about">
                عن المركز
              </Link>
              <Link className="navbar-item" to="/magazine">
                المجلة
              </Link>
              <Link className="navbar-item" to="/books">
                الكتب
              </Link>
              <Link className="navbar-item" to="/blog">
                مقالات ونصوص
              </Link>
              <Link className="navbar-item" to="/art">
                زاوية التشكيل
              </Link>
              <Link className="navbar-item" to="/news">
                أخبار المركز
              </Link>
            </div>

            <div className="navbar-end">
              <div className="navbar-item">
                <a
                  className="navbar-item twitter"
                  title="twitter"
                  target="_blank"
                  href="https://twitter.com/SudanSsc"
                >
                  <FaTwitter />
                </a>
                <a
                  className="navbar-item facebook"
                  title="facebook"
                  target="_blank"
                  href="https://facebook.com/SudanSsc"
                >
                  <FaFacebook />
                </a>
              </div>
              {loginEnabled() && <LoginMenu authenticated />}
            </div>
          </div>
        </nav>
      </div>
    )
  }
}

const LoginMenu = (authenticated) => {
  return (
    <div className="navbar-item has-dropdown is-hoverable">
      <a className="navbar-link navbar-item" href="/auth">
        منطقة الأعضاء
      </a>
      <div className="navbar-dropdown">
        {authenticated && (
          <>
            <Link className="navbar-item" to="/auth/members/AddArticle">
              اضافة مقال
            </Link>
            <hr className="navbar-hr" />
          </>
        )}

        {!authenticated && (
          <a className="navbar-item" href="/auth">
            تسجيل الدخول
          </a>
        )}
        {authenticated && (
          <a
            href="/#"
            onClick={() => logout(() => navigate('/'))}
            className="navbar-item"
          >
            خروج | Logout
          </a>
        )}
      </div>
    </div>
  )
}
export default Navbar

import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import * as api from '../services/api'
import * as utils from '../utils'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const EmailSubscribe = ({
  label,
  list,
  isCentered,
  successMessage,
  persist,
  successClass = 'has-text-grey',
  labelClassName = '',
}) => {
  const [emailSaved, setEmailSaved] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const saveEmail = async ({ email }) => {
    setEmailSaved(null)
    setIsLoading(true)
    try {
      await api.saveEmail({
        email: formik.values.email,
        list,
      })
      setEmailSaved(true)

      if (persist) {
        utils.setLocalStorage(`email:${list}`, email)
      }

      trackCustomEvent({
        category: 'Magazine',
        action: 'MailingList.subscribe',
      })
    } catch (err) {
      setEmailSaved(false)
    } finally {
      setIsLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('يجب ادخال عنوان الكتروني صحيح')
        .required('يجب ادخال عنوان الكتروني'),
    }),
    onSubmit: saveEmail,
  })

  const emailAlreadySaved = utils.getLocalStorage(`email:${list}`)

  if (emailSaved)
    return (
      <p className={successClass}>
        {successMessage || 'تمت اضافة عنوانك الى القائمة بنجاح'}
      </p>
    )

  const extraStyles = isCentered
    ? { style: { justifyContent: 'center', textAlign: 'center' } }
    : null

  return (
    <div>
      <label className={`${labelClassName} label`} htmlFor="email">
        {label}
      </label>
      {emailAlreadySaved && (
        <p className={successClass}>
          {successMessage ||
            `تمت اضافة عنوانك ${emailAlreadySaved} الى القائمة. سنقوم باعلامك عند
            صدور العدد.`}
        </p>
      )}
      {!emailAlreadySaved && (
        <div className="container">
          <div className="field has-addons" {...extraStyles}>
            <div
              className="control is-expanded has-text-centered"
              style={{ maxWidth: '400px' }}
            >
              <input
                style={{ direction: 'ltr' }}
                className="input has-text-black"
                type="email"
                name="email"
                disabled={isLoading || emailAlreadySaved}
                {...formik.getFieldProps('email')}
                placeholder="البريد الالكتروني"
              />
            </div>
            <div className="control">
              <button
                type="button"
                disabled={emailAlreadySaved}
                onClick={formik.handleSubmit}
                className={`button is-info ${isLoading ? 'is-loading' : ''}`}
              >
                أضفني
              </button>
            </div>
          </div>
        </div>
      )}
      {formik.touched.email && formik.errors.email ? (
        <div
          className={`help has-text-${
            isCentered ? 'cented' : 'right'
          } is-danger`}
          {...extraStyles}
        >
          {formik.errors.email}
        </div>
      ) : null}
      {emailSaved === false && (
        <article className="message is-danger">
          <div className="message-header">
            <p>حدث خطأ أثناء حفظ العنوان. الرجاء المحاولة في وقت آخر.</p>
          </div>
        </article>
      )}
    </div>
  )
}

EmailSubscribe.propTypes = {
  label: PropTypes.string.isRequired,
  list: PropTypes.string.isRequired,
  isCentered: PropTypes.bool,
  successMessage: PropTypes.string,
  labelClassName: PropTypes.string,
  persist: PropTypes.bool,
  successClass: PropTypes.string,
}

export default EmailSubscribe
